import { request } from './utils';

import {
  SeoResponse,
  SeosResponse,
  GetSeoRequestOptions,
  GetSeosRequestOptions,
  CreateSeoRequestOptions,
  UpdateSeoRequestOptions,
  DeleteSeoRequestOptions,
  SucceededResponse
} from './interfaces';

export function getSeo({ seoId }: GetSeoRequestOptions): Promise<SeoResponse> {
  return request({
    url: `/seos/${seoId}`,
    method: 'get'
  });
}

export function getSeos({ query }: GetSeosRequestOptions = {}): Promise<SeosResponse> {
  return request({
    url: '/seos',
    method: 'get',
    params: query
  });
}

export function createSeo({ data }: CreateSeoRequestOptions): Promise<SeoResponse> {
  return request({
    url: '/seos',
    method: 'post',
    data
  });
}

export function updateSeo({ seoId, data }: UpdateSeoRequestOptions): Promise<SeoResponse> {
  return request({
    url: `/seos/${seoId}`,
    method: 'put',
    data
  });
}

export function deleteSeo({ seoId }: DeleteSeoRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/seos/${seoId}`,
    method: 'delete'
  });
}
