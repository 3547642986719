import { request, requestFile, requestFileUpload } from './utils';
import { saveAs } from 'file-saver';

import {
  GetVideoRequestOptions,
  GetVideosRequestOptions,
  CreateVideoRequestOptions,
  UpdateVideoRequestOptions,
  VideoResponse,
  VideosResponse,
  UpdateVideoPhotoRequestOptions,
  CreateVideoPhotoRequestOptions,
  SucceededResponse,
  // CsvResponse,
  DeleteVideoPhotoRequestOptions,
  PhotoResponse,
  DeleteVideoRequestOptions,
  PublishVideoRequestOptions,
  GetBlockVideosRequestOptions,
  BlockVideosResponse
} from './interfaces';

export function getVideo({
  videoId
}: GetVideoRequestOptions): Promise<VideoResponse> {
  return request({
    url: `/videos/${videoId}`,
    method: 'get'
  });
}

export function getVideos({
  query
}: GetVideosRequestOptions): Promise<VideosResponse> {
  return request({
    url: '/videos',
    method: 'get',
    params: query
  });
}

export async function triggerExportVideosCsv() {
  try {
    const response = await requestFile.get('/videos/exportCsv');
    console.log('response blob 1', response);
    console.log('response blob 2', response.data);
    console.log('response.status:', response.status);

    if (response.status === 200) {
      const blob = new Blob([response.data], { type: 'text/csv' });
      console.log('response blob 3', blob);

      saveAs(blob, 'videos.csv');
      console.log('successful download of CSV blob 3');
    } else {
      console.error('Failed to download CSV');
    }
    return true; // Successful export

  } catch (error) {
    console.error('CSV download failed:', error);
    return false; // Failed export
  }
}

export async function triggerBatchVideosCsv(formData) {
  console.log('inside triggerBatchVideosCsv');

  formData.append('file', new Blob([formData.get('file')], { type: 'text/csv' }), 'videodata.csv');

        const formDataObject = {};
        formData.forEach((value, key) => {
          formDataObject[key] = value;
        });
        console.log('formData contents:', formDataObject); //debugger

  try {
    const response = await requestFileUpload.post('/files/cdn77BatchUpload', formData);

    if (response.status === 200) {
      console.log('response batch upload 200');
    } else {
      console.error('Failed batch upload');
    }
    return true; // Successful export

  } catch (error) {
    console.error('batch upload failed:', error);
    return false; // Failed export
  }
}


export function getBlockVideos({ blockId, query }: GetBlockVideosRequestOptions): Promise<BlockVideosResponse> {
  return request({
    url: `blocks/${blockId}/videos`,
    method: 'get',
    params: query
  });
}

export function createVideo({
  data
}: CreateVideoRequestOptions): Promise<VideoResponse> {
  return request({
    url: '/videos',
    method: 'post',
    data
  });
}

export function updateVideo({
  videoId,
  data
}: UpdateVideoRequestOptions): Promise<VideosResponse> {
  return request({
    url: `/videos/${videoId}`,
    method: 'put',
    data
  });
}

export function deleteVideo({
  videoId
}: DeleteVideoRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/videos/${videoId}`,
    method: 'delete'
  });
}

export function publishVideo({
  videoId
}: PublishVideoRequestOptions): Promise<VideosResponse> {
  return request({
    url: `/videos/${videoId}/publish`,
    method: 'post'
  });
}

export function createVideoPhoto({
  videoId,
  data
}: CreateVideoPhotoRequestOptions): Promise<PhotoResponse> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return request({
    url: `/videos/${videoId}/photos`,
    method: 'post',
    data
  });
}

export function updateVideoPhoto({
  videoId,
  data
}: UpdateVideoPhotoRequestOptions): Promise<VideoResponse> {
  return request({
    url: `/videos/${videoId}/photos`,
    method: 'put',
    data
  });
}

export function deleteVideoPhoto({
  videoId,
  photoId
}: DeleteVideoPhotoRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/videos/${videoId}/photos/${photoId}`,
    method: 'delete'
  });
}
