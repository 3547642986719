import { request } from './utils';

import {
  SourceResponse,
  SourcesResponse,
  GetSourceRequestOptions,
  GetSourcesRequestOptions,
  CreateSourceRequestOptions,
  UpdateSourceRequestOptions,
  DeleteSourceRequestOptions,
  SucceededResponse
} from './interfaces';

export function getSource({ sourceId }: GetSourceRequestOptions): Promise<SourceResponse> {
  return request({
    url: `/sources/${sourceId}`,
    method: 'get'
  });
}

export function getSources({ query }: GetSourcesRequestOptions = {}): Promise<SourcesResponse> {
  return request({
    url: '/sources',
    method: 'get',
    params: query
  });
}

export function createSource({ data }: CreateSourceRequestOptions): Promise<SourceResponse> {
  return request({
    url: '/sources',
    method: 'post',
    data
  });
}

export function updateSource({ sourceId, data }: UpdateSourceRequestOptions): Promise<SourceResponse> {
  return request({
    url: `/sources/${sourceId}`,
    method: 'put',
    data
  });
}

export function deleteSource({ sourceId }: DeleteSourceRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/sources/${sourceId}`,
    method: 'delete'
  });
}
