import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const sourceRouter: Array<RouteRecordRaw> = [
  {
    path: '/sources',
    component: Layout,
    // redirect: 'noRedirect',
    meta: {
      title: 'sources',
      icon: '#icon-tag'
    },
    children: [
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "source-create" */ '@/views/sources/Create.vue'),
        name: 'create-source',
        meta: {
          title: 'createSource',
          activeMenu: '/sources',
          hidden: true
        }
      },
      {
        path: 'edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "source-edit" */ '@/views/sources/Edit.vue'),
        name: 'edit-source',
        meta: {
          title: 'editSource',
          noCache: true,
          activeMenu: '/sources',
          hidden: true
        }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "source-list" */ '@/views/sources/List.vue'),
        name: 'list-sources',
        meta: {
          title: 'sources',
          icon: '#icon-tag'
        }
      }
    ]
  }
];

export default sourceRouter;
