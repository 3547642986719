import { request, requestFile } from './utils';
import { saveAs } from 'file-saver';


import {
  PlanResponse,
  PlansResponse,
  GetPlanRequestOptions,
  GetPlansRequestOptions,
  CreatePlanRequestOptions,
  UpdatePlanRequestOptions,
  DeletePostRequestOptions,
  SucceededResponse
} from './interfaces';

export function getPlan({ planId }: GetPlanRequestOptions): Promise<PlanResponse> {
  return request({
    url: `/plans/${planId}`,
    method: 'get'
  });
}

export function getPlans({ query }: GetPlansRequestOptions): Promise<PlansResponse> {
  return request({
    url: '/plans',
    method: 'get',
    params: query
  });
}

export function createPlan({ data }: CreatePlanRequestOptions): Promise<PlanResponse> {
  return request({
    url: '/plans',
    method: 'post',
    data
  });
}

export function updatePlan({ planId, data }: UpdatePlanRequestOptions): Promise<PlanResponse> {
  return request({
    url: `/plans/${planId}`,
    method: 'put',
    data
  });
}

export function deletePost({ postId }: DeletePostRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/posts/${postId}`,
    method: 'delete'
  });
}

export async function triggerExportPlansCsv() {
  try {
    const response = await requestFile.get('/plans/exportCsv');
    console.log('response blob 1', response);
    console.log('response blob 2', response.data); // response is the original convertCamelCase(response.data)
    console.log('response.status:', response.status) // response is the original convertCamelCase(response.data), it does NOT have the status

    if (response.status === 200) {
      const blob = new Blob([response.data], { type: 'text/csv' });
      console.log('response blob 3', blob);

      saveAs(blob, 'plans.csv');
      console.log('successful download of CSV blob 3');
    } else {
      console.error('Failed to download CSV');
    }
    return true; // Successful export

  } catch (error) {
    console.error('CSV download failed:', error);
    return false; // Failed export
  }
}
