import { AxiosError } from 'axios';
import { Plan, PartialPlan, PlanShow, PlanBest } from '@/interfaces/Plan';
import { Order, PartialOrder } from '@/interfaces/Order';
import { Question, PartialQuestion } from '@/interfaces/Question';
import { Comment } from '@/interfaces/Comment';
import { Information, PartialInformation } from '@/interfaces/Information';
import { Cooperation } from '@/interfaces/Cooperation';
import { Tag, PartialTag } from '@/interfaces/Tag';
import { Deal, PartialDeal } from '@/interfaces/Deal';
import { Segpay, PartialSegpay } from '@/interfaces/Segpay';
import { Promotion, PartialPromotion } from '@/interfaces/Promotion';
import { Block, BlockVideo, PartialBlock, PartialBlockAdvertisement } from '@/interfaces/Block';
import { Channel, PartialChannel } from '@/interfaces/Channel';
import { ChannelCampaign, PartialChannelCampaign, PartialChannelTodayRecord, PartialChannelRecord } from '@/interfaces/ChannelCampaign';
import { Post, PartialPost } from '@/interfaces/Post';
import { User, PartialUser } from '@/interfaces/User';
import { Admin, PartialAdmin, AdminStatus, AdminAllocatedMember } from '@/interfaces/Admin';
import { Role, PartialRole } from '@/interfaces/Role';
import { Site, PartialSite } from '@/interfaces/Site';
import { PartialPermissions } from '@/interfaces/Permissions';
import { IpWhitelist } from '@/interfaces/IpWhitelist';
import { Video, PartialVideo, VideoStatus, VideoSourceType } from '@/interfaces/Video';
import { Banner, BannerPosition, BannerType, PartialBanner, BannerCta } from '@/interfaces/Banner';
import { PartialPhoto, Photo } from '@/interfaces/Photo';
import { Support } from '@/interfaces/Support';
import { Subscription, SubscriptionChannelStatistic } from '@/interfaces/Subscription';
import { Advertisement, PartialAdvertisement } from '@/interfaces/Advertisements';
import { Director, PartialDirector } from '@/interfaces/Director';
import { DailyData } from '@/interfaces/DailyData';
import { ChannelAdmin } from '@/interfaces/ChannelAdmin';
import { Seo, PartialSeo } from '@/interfaces/Seo';
import { Source, PartialSource } from '@/interfaces/Source';

export interface RequestOptions<TQuery = any, TData = any> {
  query?: TQuery
  data?: TData
}

export interface Response<TData = any> {
  data?: TData
  links?: {
    first: string
    last: string
    prev: string | null
    next: string | null
  }
  meta?: {
    currentPage: number
    from: number
    lastPage: number
    path: string
    perPage: number
    to: number
    total: number
  }
}

export type SucceededResponse = Response<{ success: boolean }>
// export type CsvResponse = Blob

export interface ErrorData {
  errors?: { [key: string]: string }
  message?: string
}
export type ResponseError = Partial<AxiosError<ErrorData>>;

interface BaseQuery {
  page?: number
  perPage?: number
  keyword?: string
  sortBy?: string
  descSortBy?: string
}

export enum IntBoolean {
  TRUE = 1,
  FALSE = 0
}

// Auth
export interface AuthRequestData {
  username: string
  password: string
}

export type LoginRequestData = AuthRequestData;

export type LoginRequestOptions = RequestOptions<any, LoginRequestData>;

export type LoginResponse = Response<Admin & {
  accessToken: string
  tokenType: string
  qrCode: string
  key: string
}>;

type GoogleOptauthRequestData = AuthRequestData;

interface GoogleVerifyRequestData {
  username: string
  key: string
  token: string
}

export type GoogleOptauthRequestOptions = RequestOptions<any, GoogleOptauthRequestData>
export type GoogleVerifyRequestOptions = RequestOptions<any, GoogleVerifyRequestData>

// Admin
interface AdminQuery extends BaseQuery {
  status?: AdminStatus
}

type RoleQuery = BaseQuery

interface PlanQuery extends BaseQuery {
  show?: PlanShow
  isBest?: PlanBest
}

type OrderQuery = BaseQuery

type QuestionQuery = BaseQuery

interface CommentQuery extends BaseQuery {
  status?: string

}

type InformationQuery = BaseQuery

interface CooperationQuery extends BaseQuery {
  status?: string
  archived?: string
}

type AdvertisementsQuery = BaseQuery

interface TagQuery extends BaseQuery {
  type?: string
}

type DealQuery = BaseQuery

interface PromotionQuery extends BaseQuery {
  status?: string
}

interface BlockQuery extends BaseQuery {
  type?: string
}

interface ChannelQuery extends BaseQuery {
  type?: string
  isArchived?: IntBoolean
  isBanned?: IntBoolean
}

interface PostQuery extends BaseQuery {
  status?: string
  isSticky?: string
}

interface UserQuery extends BaseQuery {
  status?: string
}

interface BannerQuery extends BaseQuery {
  type?: BannerType
  position?: BannerPosition
  isCta?: BannerCta
}

type PhotoQuery = BaseQuery

interface SupportQuery extends BaseQuery {
  isArchived?: string
  status?: string
}

interface SubscriptionQuery extends BaseQuery {
  planId: string
}

interface DailyDataQuery extends BaseQuery {
  date?: string
}
interface SegpayQuery extends BaseQuery {
  startDate?: string
  endDate?: string
}

interface SeoQuery extends BaseQuery {
  tagId?: string
}

interface SourceQuery extends BaseQuery {
  genreId?: string
}

export interface AnalyticQuery extends BaseQuery {
  date?: string
  startDate?: string
  endDate?: string
  sort?: string
  orderBy?: string
  channelId?: number | string
  domain?: string
}

interface AdminAllocatedMembersQuery extends BaseQuery{
  startDate?: string
  endDate?: string
}

export type AdminIdOptions = { adminId: string };
export type GetAdminRequestOptions = RequestOptions & AdminIdOptions;
export type GetAdminsRequestOptions = RequestOptions<AdminQuery>;
export type CreateAdminRequestOptions = RequestOptions<any, PartialAdmin>;
export type UpdateAdminRequestOptions = CreateAdminRequestOptions & AdminIdOptions;
export type DeleteAdminRequestOptions = AdminIdOptions;
export type ResetAdminRequestOptions = AdminIdOptions;
export type EnableAdminRequestOptions = AdminIdOptions;
export type DisableAdminRequestOptions = AdminIdOptions;

export type AdminResponse = Response<Admin>;
export type AdminsResponse = Response<Admin[]>;

export type GetAdminAllocatedMembersRequestOptions = RequestOptions<AdminAllocatedMembersQuery> & AdminIdOptions;
export type AdminAllocatedMembersResponse = Response<AdminAllocatedMember[]>;

export type GetPlanRequestOptions = RequestOptions & { planId: string };
export type GetPlansRequestOptions = RequestOptions<PlanQuery>;
export type CreatePlanRequestOptions = RequestOptions<any, PartialPlan>;
export type UpdatePlanRequestOptions = CreatePlanRequestOptions & { planId: string };
export type PlanResponse = Response<Plan>;
export type PlansResponse = Response<Plan[]>;

export type GetOrderRequestOptions = RequestOptions & { id: string };
export type GetOrdersRequestOptions = RequestOptions<OrderQuery>;
export type UpdateOrderRequestOptions = RequestOptions<any, PartialOrder> & { id: string};
export type OrderResponse = Response<Order>;
export type OrdersResponse = Response<Order[]>;

// Get order success ratio
interface OrderSuccessRatioQuery extends BaseQuery {
  startDate?: string
  endDate?: string
}
export type GetOrderSuccessRatioRequestOptions = RequestOptions<OrderSuccessRatioQuery>
export type OrderSuccessRatioResponse = Response;

export type QuestionIdOptions = { questionId: string };
export type GetQuestionRequestOptions = RequestOptions & { questionId: string };
export type GetQuestionsRequestOptions = RequestOptions<QuestionQuery>;
export type CreateQuestionRequestOptions = RequestOptions<any, PartialQuestion>;
export type UpdateQuestionRequestOptions = CreateQuestionRequestOptions & { questionId: string };
export type DeleteQuestionRequestOptions = QuestionIdOptions;
export type QuestionResponse = Response<Question>;
export type QuestionsResponse = Response<Question[]>;

export type CommentIdOptions = { commentId: string };
export type GetCommentsRequestOptions = RequestOptions<CommentQuery>;
export type DeleteCommentRequestOptions = CommentIdOptions;
export type EnableCommentRequestOptions = CommentIdOptions;
export type DisableCommentRequestOptions = CommentIdOptions;
export type BanCommentRequestOptions = CommentIdOptions;
export type RejectReportCommentRequestOptions = CommentIdOptions;
export type CommentsResponse = Response<Comment[]>;

export type GetInformationRequestOptions = RequestOptions & { informationId: string };
export type GetInformationsRequestOptions = RequestOptions<InformationQuery>;
export type CreateInformationRequestOptions = RequestOptions<any, PartialInformation>;
export type UpdateInformationRequestOptions = CreateInformationRequestOptions & { informationId: string };
export type InformationResponse = Response<Information>;
export type InformationsResponse = Response<Information[]>;

export type CooperationIdOptions = { cooperationId: string };
export type GetCooperationRequestOptions = RequestOptions & { cooperationId: string };
export type GetCooperationsRequestOptions = RequestOptions<CooperationQuery>;
export type DeleteCooperationRequestOptions = CooperationIdOptions;
export type SolveCooperationRequestOptions = CooperationIdOptions;
export type ArchiveCooperationRequestOptions = CooperationIdOptions;
export type CooperationResponse = Response<Cooperation>;
export type CooperationsResponse = Response<Cooperation[]>;

export type GetAdvertisementRequestOptions = RequestOptions & { advertisementsId: string };
export type GetAdvertisementsRequestOptions = RequestOptions<AdvertisementsQuery>;
export type AdvertisementResponse = Response<Advertisement>;
export type AdvertisementsResponse = Response<Advertisement[]>;
export type UpdateAdvertisementRequestOptions = RequestOptions<any, PartialAdvertisement> & { advertisementsId: string };
export type TagIdOptions = { tagId: string };
export type GetTagRequestOptions = RequestOptions & { tagId: string };
export type GetTagsRequestOptions = RequestOptions<TagQuery>;
export type CreateTagRequestOptions = RequestOptions<any, PartialTag>;
export type UpdateTagRequestOptions = CreateTagRequestOptions & { tagId: string };
export type DeleteTagRequestOptions = TagIdOptions;
export type TagResponse = Response<Tag>;
export type TagsResponse = Response<Tag[]>;

export type SourceIdOptions = { sourceId: string };
export type GetSourceRequestOptions = RequestOptions & { sourceId: string };
export type GetSourcesRequestOptions = RequestOptions<SourceQuery>;
export type CreateSourceRequestOptions = RequestOptions<any, PartialSource>;
export type UpdateSourceRequestOptions = CreateSourceRequestOptions & { sourceId: string };
export type DeleteSourceRequestOptions = SourceIdOptions;
export type SourceResponse = Response<Source>;
export type SourcesResponse = Response<Source[]>;

export type SeoIdOptions = { seoId: string };
export type GetSeoRequestOptions = RequestOptions & { seoId: string };
export type GetSeosRequestOptions = RequestOptions<SeoQuery>;
export type CreateSeoRequestOptions = RequestOptions<any, PartialSeo>;
export type UpdateSeoRequestOptions = CreateSeoRequestOptions & { seoId: string };
export type DeleteSeoRequestOptions = SeoIdOptions;
export type SeoResponse = Response<Seo>;
export type SeosResponse = Response<Seo[]>;

export type DealIdOptions = { dealId: string };
export type GetDealRequestOptions = RequestOptions & { dealId: string };
export type GetDealsRequestOptions = RequestOptions<DealQuery>;
export type CreateDealRequestOptions = RequestOptions<any, PartialDeal>;
export type UpdateDealRequestOptions = CreateDealRequestOptions & { dealId: string };
export type DeleteDealRequestOptions = DealIdOptions;
export type DealResponse = Response<Deal>;
export type DealsResponse = Response<Deal[]>;

export type BlockIdOptions = { blockId: string };
export type GetBlockRequestOptions = RequestOptions & BlockIdOptions;
export type GetBlocksRequestOptions = RequestOptions<BlockQuery>;
export type CreateBlockRequestOptions = RequestOptions<any, PartialBlock>;
export type UpdateBlockRequestOptions = CreateBlockRequestOptions & BlockIdOptions;
export type DeleteBlockRequestOptions = BlockIdOptions;
export type UpdateBlockVideosRequestOptions = RequestOptions<any, {
  videoIds: {
    id: number
    sort: number
  }[]
}>& BlockIdOptions;
export type UpdateBlockModelsRequestOptions = RequestOptions<any, {
  modelIds: {
    id: number
    sort: number
  }[]
}>& BlockIdOptions;
export type UpdateBlockAdvertisementRequestOptions = RequestOptions<any, PartialBlockAdvertisement> & BlockIdOptions;
export type BlockResponse = Response<Block>;
export type BlocksResponse = Response<Block[]>;

export type BlockVideosResponse = Response<BlockVideo[]>;
// export type BlockModelsResponse = Response<BlockModel[]>;

export type PromotionIdOptions = { promotionId: string };
export type GetPromotionRequestOptions = RequestOptions & { promotionId: string };
export type GetPromotionsRequestOptions = RequestOptions<PromotionQuery>;
export type CreatePromotionRequestOptions = RequestOptions<any, PartialPromotion>;
export type UpdatePromotionRequestOptions = CreatePromotionRequestOptions & { promotionId: string };
export type UpdatePromotionPlansRequestOptions = RequestOptions<any> & { promotionId: string, data: object };
export type DeletePromotionRequestOptions = PromotionIdOptions;
export type PromotionResponse = Response<Promotion>;
export type PromotionsResponse = Response<Promotion[]>;

export type ChannelIdOptions = { channelId: string };
export type GetChannelRequestOptions = RequestOptions & ChannelIdOptions;
export type GetChannelsRequestOptions = RequestOptions<ChannelQuery>;
export type GetChannelAnalyticRequestOptions = RequestOptions<AnalyticQuery> & ChannelIdOptions;
export type GetChannelTypeRequestOptions = RequestOptions<AnalyticQuery>;
export type ChannelUserLineChartRecordsResponse = Response<{
  date: string[]
  visitorsCount: number[]
  subscribersCount: number[]
}>
export type ChannelPieChartRecordsResponse = Response<{
  name: string
  value: number
}[]>
export type ChannelRevenueLineChartRecordsResponse = Response<{
  date: string[]
  revenue: number[]
}>

export interface SubscriptionReferralsQuery extends BaseQuery {
  date: string
  channelId?: string
};

export type GetChannelSubscriptionReferralsRequestOptions = RequestOptions<SubscriptionReferralsQuery>;

export type ChannelSubscriptionReferralsResponse = Response<{
  datetime?: string
  referral: string
}[]>

export type RefundStatisticsQuery = SubscriptionReferralsQuery;
export type GetChannelRefundStatisticsRequestOptions = RequestOptions<RefundStatisticsQuery>;
export type ChannelRefundStatisticsResponse = Response<{
  name?: string
  amount?: number
  createdAt?: string
}[]>

export type CreateChannelRequestOptions = RequestOptions<any, PartialChannel>;
export type UpdateChannelRequestOptions = CreateChannelRequestOptions & ChannelIdOptions;
export type ArchiveChannelRequestOptions = ChannelIdOptions;
export type RecoverChannelRequestOptions = ChannelIdOptions;
export type ChannelResponse = Response<Channel>;
export type ChannelsResponse = Response<Channel[]>;
export type ChannelsTodayRecordResponse = Response<PartialChannelTodayRecord>;
export type ChannelsRecordResponse = Response<PartialChannelRecord>;

export type ChannelCampaignIdOptions = ChannelIdOptions & { campaignId: string };
export type GetChannelCampaignRequestOptions = RequestOptions & ChannelCampaignIdOptions;
export type GetChannelCampaignsRequestOptions = RequestOptions & ChannelIdOptions;
export type CreateChannelCampaignRequestOptions = RequestOptions<any, PartialChannelCampaign> & ChannelIdOptions;
export type UpdateChannelCampaignRequestOptions = CreateChannelCampaignRequestOptions & ChannelCampaignIdOptions;
export type DeleteChannelCampaignRequestOptions = ChannelCampaignIdOptions;
export type ChannelCampaignResponse = Response<ChannelCampaign>;

export type ChannelAdminIdOptions = ChannelIdOptions & { adminId: string };
export type GetChannelAdminsRequestOptions = RequestOptions & ChannelIdOptions;
export type CreateChannelAdminRequestOptions = RequestOptions<any, AuthRequestData> & ChannelIdOptions;
export type DeleteChannelAdminRequestOptions = ChannelAdminIdOptions;
export type ChannelAdminResponse = Response<ChannelAdmin>;
export type ChannelAdminsResponse = Response<ChannelAdmin[]>;

export type PostIdOptions = { postId: string };
export type GetPostRequestOptions = RequestOptions & { postId: string };
export type GetPostsRequestOptions = RequestOptions<PostQuery>;
export type CreatePostRequestOptions = RequestOptions<any, PartialPost>;
export type UpdatePostRequestOptions = CreatePostRequestOptions & { postId: string };
export type DeletePostRequestOptions = PostIdOptions;
export type PostResponse = Response<Post>;
export type PostsResponse = Response<Post[]>;

export type UserIdOptions = { userId: string };
export type GetUserRequestOptions = RequestOptions & { userId: string };
export type GetUsersRequestOptions = RequestOptions<UserQuery>;
export type BatchCreateUserRequestOptions = RequestOptions<any, PartialUser>;
export type UpdateUserRequestOptions = BatchCreateUserRequestOptions & { userId: string };
export type DisableUserRequestOptions = UserIdOptions;
export type EnableUserRequestOptions = UserIdOptions;
export type SendEmailUserRequestOptions = UserIdOptions;
export type SendResetUserRequestOptions = UserIdOptions;
export type UserResponse = Response<User>;
export type UsersResponse = Response<User[]>;

export type BannerIdOptions = { bannerId: string };
export type GetBannerRequestOptions = RequestOptions & { bannerId: string };
export type GetBannersRequestOptions = RequestOptions<BannerQuery>;
export type CreateBannerRequestOptions = RequestOptions<any, PartialBanner>;
export type UpdateBannerRequestOptions = CreateBannerRequestOptions & { bannerId: string };
export type DeleteBannerRequestOptions = BannerIdOptions;
export type BannerResponse = Response<Banner>;
export type BannersResponse = Response<Banner[]>;

export type PhotoIdOptions = { photoId: string };
export type GetPhotoRequestOptions = RequestOptions & { photoId: string };
export type GetPhotosRequestOptions = RequestOptions<PhotoQuery>;
export type CreatePhotoRequestOptions = RequestOptions<any, PartialPhoto>;
export type UpdatePhotoRequestOptions = CreatePhotoRequestOptions & { photoId: string };
export type DeletePhotoRequestOptions = PhotoIdOptions;
export type PhotoResponse = Response<Photo>;
export type PhotosResponse = Response<Photo[]>;

export type SupportIdOptions = { supportId: string };
export type GetSupportRequestOptions = RequestOptions & { supportId: string };
export type GetSupportsRequestOptions = RequestOptions<SupportQuery>;
export type DeleteSupportRequestOptions = SupportIdOptions;
export type SolveSupportRequestOptions = SupportIdOptions;
export type ArchiveSupportRequestOptions = SupportIdOptions;
export type RecoverSupportRequestOptions = SupportIdOptions;
export type SupportResponse = Response<Support>;
export type SupportsResponse = Response<Support[]>;

export type GetSubscriptionsRequestOptions = RequestOptions<SubscriptionQuery>;
export type SubscriptionsResponse = Response<Subscription[]>;
export type SubscriptionChannelStatisticResponse = Response<SubscriptionChannelStatistic[]>;

export type SegpayIdOptions = { id: string };
export type GetSegpayRequestOptions = RequestOptions & { id: string };
export type GetSegpaysRequestOptions = RequestOptions<SegpayQuery>;
export type CreateSegpayRequestOptions = RequestOptions<any, PartialSegpay>;
export type UpdateSegpayRequestOptions = CreateSegpayRequestOptions & { id: string };
export type DeleteSegpayRequestOptions = SegpayIdOptions;
export type SegpayResponse = Response<Segpay>;
export type SegpaysResponse = Response<Segpay[]>;

// Dashboard
interface DashboardQuery {
  page?: number
  startDate?: string
  endDate?: string
  type?: 'visitors' | 'subscribers'
}
export type GetDashboardRequestOptions = RequestOptions<DashboardQuery>;
export type DashboardResponse = Response;
interface Revenue{
  amount: number
  count: number
  atv: number
}
export type DashboardRevenueGatherResponse = Response<{
  newRevenue?: Revenue
  renewRevenue?: Revenue
  usRenewRevenue?: Revenue
  totalRevenue?: Revenue
  refund?: Revenue
  usRefund?: Revenue
  orderSuccessRate?: number
}>;

export type DashboardRevenueTrendResponse = Response<{
  date: string[]
  newRevenue: number[]
  renewRevenue: number[]
  usRenewRevenue: number[]
  totalRevenue: number[]
}>;
export type DashboardMemberTrendResponse = Response<{
  date: string[]
  newCount: number[]
  renewCount: number[]
  usRenewCount: number[]
  totalCount: number[]
}>;
export type DashboardChannelListResponse = Response<{
  date: string
  arpu: number
  channel: PartialChannel
  channelId: number
  revenue: number
  subscribersCount: number
  visitorsCount: number
  returningUserCount: number
  refundCount: number
  refundAmount: number
  renewCount: number
  renewRevenue: number
  renewArpu: number
}[]>;
export type DashboardPlanListResponse = Response<{
  plan: PartialPlan
  planId: number
  revenue: number
  subscribersCount: number
}[]>;
export type DashboardDevicesResponse = Response<{
  name: string
  value: number
}[]>;
export type DashboardRealTimeUsersResponse = Response<{
  date: string[]
  value: {
    totalVisitorsCount: number[] // 總訪客
    channelVisitorsCount: number[] // 渠道訪客
    directVisitorsCount: number[] // 自來量訪客
    totalSubscribersCount: number[] // 總會員
    channelSubscribersCount: number[] // 渠道會員
    directSubscribersCount: number[] // 自來量會員
  }
}>;
export type DashboardRealTimeRevenuesResponse = Response<{
  date: string[]
  value: {
    totalRevenue: number[] // 總營收
    channelRevenue: number[] // 渠道營收
    directRevenue: number[] // 自來量營收
  }
}>;

export type GetAnalyticRequestOptions = RequestOptions<AnalyticQuery>;
export type AnalyticResponse = Response;

export type GetDailyDataRequestOptions = RequestOptions<DailyDataQuery>;
export type DailyDataResponse = RequestOptions<DailyData[]>;

interface Revenue {
  amount: number
  count: number
}
export type RevenueDailyResponse = Response<{
  todayRevenue?: Revenue
  totalRevenue?: Revenue
  intervalRevenue?: Revenue
  renewRevenue?: Revenue
  usRenewRevenue?: Revenue
  revenueList?: Response<{
    _id: string
    date: string
    newRevenue: number
    newCount: number
    renewRevenue: number
    usRenewRevenue: number
    renewCount: number
    usRenewCount: number
    refundAmount: number
    usRefundAmount: number
    refundCount: number
    usRefundCount: number
    updatedAt: string
    createdAt: string
  }[]>
}>;

export type CompanyDataDailyResponse = Response<{
  todayRevenue?: Revenue
  totalRevenue?: Revenue
  // intervalRevenue?: Revenue
  renewRevenue?: Revenue
  companyDataList?: Response<{
    _id: string
    date: string
    visitorsCount: number
    newCount: number
    totalDailyRevenue: number
    totalOrderCount: number
    successRate: string
    failRate: string
    pendingRate: string
    refundRate: string
    newRevenue: number
    renewRevenue: number
    renewCount: number
    refundAmount: number
    refundCount: number
    domain: string
    updatedAt: string
    createdAt: string
  }[]>
}>;

// Video
interface VideoQuery extends BaseQuery {
  status?: VideoStatus
  source?: VideoSourceType
  published?: number
  keyword?: string
  isFree?: string
  sort?: string
}

export interface VideoIdOption {
  videoId: string
}
export interface BlockIdOption {
  blockId: string
}
export type GetVideoRequestOptions = RequestOptions & VideoIdOption;
export type GetVideosRequestOptions = RequestOptions<VideoQuery>;
export type GetBlockVideosRequestOptions = RequestOptions<VideoQuery> & BlockIdOption;
export type CreateVideoRequestOptions = RequestOptions<any, PartialVideo>;
export type UpdateVideoRequestOptions = CreateVideoRequestOptions & VideoIdOption;
export type DeleteVideoRequestOptions = VideoIdOption;
export type PublishVideoRequestOptions = VideoIdOption;

export type VideoResponse = Response<Video>;
export type VideosResponse = Response<Video[]>;

export type UpdateVideoPhotoRequestOptions = RequestOptions<any, { photos: PartialPhoto[] }> & VideoIdOption;
export type CreateVideoPhotoRequestOptions = RequestOptions<any, { image: string }> & VideoIdOption;
export type DeleteVideoPhotoRequestOptions = VideoIdOption & { photoId: string };

// File
interface ImageData {
  image: File
  imageMeta?: string
  type: string
}
export type UploadImageRequestOptions = RequestOptions<any, ImageData>

export interface ImageResponseData {
  path: string
  link: string
}

export type ImageResponse = Response<ImageResponseData>;

interface VideoData {
  video: File
}

export type UploadVideoRequestOptions = RequestOptions<any, VideoData>

export interface VideoResponseData {
  path: string
}

export type UploadVideoResponse = Response<VideoResponseData>;

interface FileData {
  /** 原始檔名  */
  filename: string
  /** 類型 */
  type: string
  /** 分塊號 */
  blockId: number
  /** 分塊總數 */
  blockTot: number
  /** 分塊檔 */
  block: Blob
  /** 檔案所在目錄 */
  dir: string
  /** 原始檔名 */
  oriname: string
  /** 副檔名 */
  ext: string
  /** 起始檔案分塊大小 */
  start: number
  /* 存檔名 */
  uuid: string

}
export type UploadFileRequestOptions = RequestOptions<any, FileData>

export interface FileResponse {
  blockId: string
  blockTot: number // 全部上傳完成，返回 block_tot
  datetime: number
  uuid: string
  source?: string
  start: number
  path?: string
  time: number
  tempFileCount: number
}

export interface FileInfo {
  uuid: string
  dir: string
  ext: string
}

export interface CheckFileResponse {
  size: number
  source?: string
  path?: string
}

export interface FileUploadedResult {
  path: string
}

export type CheckFileOnCloudRequestOptions = RequestOptions<FileInfo>
export type CheckFileOnCloudResponse = CheckFileResponse

export type CheckFileOnServerRequestOptions = RequestOptions<FileInfo>
export type CheckFileOnServerResponse = CheckFileResponse

export type CheckChunkListRequestOptions = RequestOptions<FileInfo & {
  blockTot: number
}>
export interface CheckChunkListResponse {
  blockArr: number[]
}

// Director

interface DirectorQuery extends BaseQuery {
  keyword?: string
}

export interface DirectorIdOption {
  directorId: string
}

export type GetDirectorsRequestOptions = RequestOptions<DirectorQuery>;
export type GetDirectorRequestOptions = RequestOptions & DirectorIdOption;
export type CreateDirectorRequestOptions = RequestOptions<any, PartialDirector>;
export type UpdateDirectorRequestOptions = CreateDirectorRequestOptions & DirectorIdOption;
export type DeleteDirectorRequestOptions = DirectorIdOption;
export type ResetDirectorRequestOptions = DirectorIdOption;
export type EnableDirectorRequestOptions = DirectorIdOption;
export type DisableDirectorRequestOptions = DirectorIdOption;

export type DirectorResponse = Response<Director>;
export type DirectorsResponse = Response<Director[]>;

// IpWhitelist
interface IpWhitelistQuery extends BaseQuery {
  page?: number
  keyword?: string
}

export type IpWhitelistIdOptions = {
  whitelistId: string
};

export type IpWhitelistsResponse = Response<IpWhitelist[]>
export type IpWhitelistResponse = Response<IpWhitelist>;

export type GetIpWhitelistRequestOptions = RequestOptions & IpWhitelistIdOptions;
export type GetIpWhitelistsRequestOptions = RequestOptions<IpWhitelistQuery>;

export type SearchIpWhitelistsRequestOptions = RequestOptions<IpWhitelistQuery>;

export type CreateIPAdressRequestOptions = RequestOptions<any, {
  ipAddress?: string
}>;
export type UpdateIpAddressRequestOptions = CreateIPAdressRequestOptions & IpWhitelistIdOptions;
export type DeleteIpAddressRequestOptions = IpWhitelistIdOptions;

// Roles
export type RoleIdOptions = { roleId: string };
export type GetRoleRequestOptions = RequestOptions & RoleIdOptions;
export type GetRolesRequestOptions = RequestOptions<RoleQuery>;
export type CreateRoleRequestOptions = RequestOptions<any, Omit<PartialRole, 'permissions'> & { permissions: number[] }>;
export type UpdateRoleRequestOptions = CreateRoleRequestOptions & RoleIdOptions;

// Role to Admins
export interface AdminsData {
  admins: number[]
}

export type UpdateRoleToAdminsRequestOptions = RequestOptions<any, AdminsData> & RoleIdOptions;
export type DeleteRoleRequestOptions = RoleIdOptions;

export type RoleResponse = Response<Role>;
export type RolesResponse = Response<Role[]>;

// Permissions
export type PermissionsResponse = Response<PartialPermissions[]>;

// Sites
interface SiteQuery extends BaseQuery {
  startDate?: string
  endDate?: string
}

export type SiteIdOption = { siteId: string };

export type GetSiteRequestOptions = RequestOptions & SiteIdOption;
export type GetSitesRequestOptions = RequestOptions<SiteQuery>;

export type CreateSiteRequestOptions = RequestOptions<any, PartialSite>;
export type UpdateSiteRequestOptions = CreateSiteRequestOptions & SiteIdOption;
export type DeleteSiteRequestOptions = SiteIdOption;

export type SiteResponse = Response<Site>;
export type SitesResponse = Response<Site[]>;
