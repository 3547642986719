import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const videoRouter: Array<RouteRecordRaw> = [
  {
    path: '/videos',
    component: Layout,
    // redirect: 'noRedirect',
    meta: {
      title: 'OS header',
      icon: '#icon-navigation'
    },
    children: [
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "video-create" */ '@/views/videos/Create.vue'),
        name: 'create-video',
        meta: {
          title: 'createVideo',
          activeMenu: '/videos',
          hidden: true
        }
      },
      {
        path: 'batch-create',
        component: () => import(/* webpackChunkName: "video-create" */ '@/views/videos/BatchCreate.vue'),
        name: 'batch-create-video',
        meta: {
          title: 'batchCreateVideo',
          activeMenu: '/videos',
          hidden: true
        }
      },
      {
        path: 'edit/:id',
        component: () => import(/* webpackChunkName: "video-edit" */ '@/views/videos/Edit.vue'),
        name: 'edit-video',
        meta: {
          title: 'editVideo',
          noCache: true,
          activeMenu: '/videos',
          hidden: true
        }
      },
      {
        path: 'show/:id',
        component: () => import(/* webpackChunkName: "video-edit" */ '@/views/videos/Show.vue'),
        name: 'show-video',
        meta: {
          title: 'showVideo',
          noCache: true,
          activeMenu: '/videos',
          hidden: true
        }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "video-list" */ '@/views/videos/List.vue'),
        name: 'list-videos',
        meta: {
          title: 'videos',
          icon: '#icon-video'
        }
      },
      {
        path: 'posts/create',
        component: () => import(/* webpackChunkName: "post-create" */ '@/views/posts/Create.vue'),
        name: 'create-post',
        meta: {
          title: 'createPost',
          activeMenu: '/posts',
          hidden: true
        }
      },
      {
        path: 'posts/edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "post-edit" */ '@/views/posts/Edit.vue'),
        name: 'edit-post',
        meta: {
          title: 'editPost',
          noCache: true,
          activeMenu: '/posts',
          hidden: true
        }
      },
      {
        path: 'posts',
        component: () => import(/* webpackChunkName: "post-list" */ '@/views/posts/List.vue'),
        name: 'list-posts',
        meta: {
          title: 'posts',
          icon: '#icon-post'
        }
      },
      {
        path: 'posts/:id(\\d+)',
        component: () => import(/* webpackChunkName: "post-show" */ '@/views/posts/Show.vue'),
        name: 'show-post',
        meta: {
          title: 'showPost',
          noCache: true,
          activeMenu: '/posts',
          hidden: true
        }
      },
      {
        path: 'cooperations',
        component: () => import(/* webpackChunkName: "cooperation-list" */ '@/views/cooperations/List.vue'),
        name: 'list-cooperations',
        meta: {
          title: 'cooperations',
          icon: '#icon-cooperation'
        }
      },
      {
        path: 'cooperations/:id(\\d+)',
        component: () => import(/* webpackChunkName: "cooperation-show" */ '@/views/cooperations/Show.vue'),
        name: 'show-cooperation',
        meta: {
          title: 'showCooperation',
          noCache: true,
          activeMenu: '/cooperations',
          hidden: true
        }
      },
      {
        path: 'sites/create',
        component: () => import(/* webpackChunkName: "site-create" */ '@/views/sites/Create.vue'),
        name: 'create-site',
        meta: {
          title: 'createSite',
          activeMenu: '/sites',
          hidden: true
        }
      },
      {
        path: 'sites/edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "site-edit" */ '@/views/sites/Edit.vue'),
        name: 'edit-site',
        meta: {
          title: 'editSite',
          noCache: true,
          activeMenu: '/sites',
          hidden: true
        }
      },
      {
        path: 'sites',
        component: () => import(/* webpackChunkName: "site-list" */ '@/views/sites/List.vue'),
        name: 'list-sites',
        meta: {
          title: 'sites',
          icon: '#icon-site'
        }
      },
      {
        path: 'sites/:id(\\d+)',
        component: () => import(/* webpackChunkName: "site-show" */ '@/views/sites/Show.vue'),
        name: 'show-site',
        meta: {
          title: 'showSite',
          noCache: true,
          activeMenu: '/sites',
          hidden: true
        }
      }
    ]
  }
];

export default videoRouter;
